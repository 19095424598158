import {useState} from "react";
import {Button, TextField} from "@material-ui/core";
import {apiPost, tryLogin as tryLoginReq} from "./api";


const Login = ({onLogin}) => {
    let [username, setUsername] = useState("");
    let [password, setPassword] = useState("");
    let [error, setError] = useState("");

    const tryLogin = () => {
        apiPost("users/login", JSON.stringify({username, password}))
            .then(({status}) => {
                if (status == 200) {
                    onLogin();
                } else {
                    setError("Invalid password");
                }
            })
    }

    return (
        <div>
            <TextField
                error={error} type="text" label="Username" value={username}
                onChange={e => setUsername(e.target.value)}/>
            <TextField onKeyPress={e => {
                if (e.keyCode == 13) {

                }
            }} error={error} type="password" label="Password" value={password} helperText={error}
                       onChange={e => setPassword(e.target.value)}/>
            <Button variant="contained" color="primary" onClick={tryLogin}>Login</Button>
        </div>
    )
}

export default Login