import {AppBar, Button, Container, Grid, Icon, SvgIcon, Tab, Tabs, Toolbar} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import logo from "./images/Logo.png";
import css from "./css/testik.css";


const Header = ({isLoggedIn, setLoggedIn}) => {
    let history = useHistory();

    return (
        <>
            <div className="header-div">
                <img src={logo} style={{float: "left"}}/>

                {isLoggedIn ?
                    <>
                        <span className="header header-first" onClick={() => history.push("/configurations")}>Configurations</span>
                        <span className="header" onClick={() => history.push("/categories")}>Categories</span>
                        <span className="header" onClick={() => history.push("/export")}>Export</span>
                        <span className="header" onClick={() => history.push("/results")}>Results</span>
                        <span className="header" onClick={() => history.push("/expenses")}>Expenses</span>
                        <span className="header header-right sign-up" onClick={() => setLoggedIn(false)}>Log out</span>
                    </> :
                    <>
                        <span className="header header-first">About</span>
                        <span className="header">Features</span>
                        <span className="header">Pricing</span>
                        <span className="header">Testimonials</span>
                        <span className="header">Help</span>

                        <span className="header header-right sign-up" onClick={() => history.push("/register")}>Sign up</span>
                        <span className="header header-right sign-in" onClick={() => history.push("/login")}>Sign in</span>
                    </>

                }

            </div>
        </>

    )
}

export default Header