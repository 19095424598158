let setLoggedIn;

export const setSetLoggedIn = f => {
    setLoggedIn = f;
}

export const tryLogin = (username, password) => {
    return apiReq("POST", "users/login", null, req => {
        req.setRequestHeader("Authorization", "Basic " + btoa(username + ":" + password));
    });
}

export const apiPost = (path, data) => {
    return apiReq("POST", path, data);
}

export const apiPut = (path, data) => {
    return apiReq("PUT", path, data);
}

export const apiGet = (path) => {
    return apiReq("GET", path);
}

export const apiDelete = (path, body) => {
    return apiReq("DELETE", path, body)
}

export const getApiUrl = () => {
    let hostname = window.location.hostname;
    let port = window.location.port;

    if (hostname === "localhost") {
        port = 8080;
        return "http://" + hostname + ":" + port + "/api/";
    } else {
        return "https://" + hostname + ":" + port + "/api/";
    }
}

const apiReq = (method, path, data, modification) => {
    return new Promise(resolve => {
        let http = new XMLHttpRequest();
        http.withCredentials = true;

        let url = getApiUrl() + path;

        http.open(method, url, true);
        if (data) {
            http.setRequestHeader("Content-Type", "application/json")
        }

        if (modification) {
            modification(http);
        }

        http.send(data);

        http.onreadystatechange = () => {
            if (http.readyState == 4 && http.status > 0) {
                if (http.status == 401) {
                    setLoggedIn(false);
                    return;
                }
                resolve({
                    status: http.status,
                    text: http.responseText
                });
            }
        };
    });
}