import {BrowserRouter, Route, Switch, useHistory, Redirect} from "react-router-dom";
import Login from "./Login";
import {useState} from "react";
import Configurations from "./Configurations";
import CreateConfiguration from "./CreateConfiguration";
import React from "react"
import Header from "./Header";
import Categories from "./Categories";
import Register from "./Register";
import Export from "./Export";
import css from "./css/main.css"
import Results from "./Results";
import {setSetLoggedIn} from "./api";
import Expenses from "./Expenses";

function App() {
    let [loggedIn, setLoggedIn] = useState(false);
    let history = useHistory();
    setSetLoggedIn(setLoggedIn);

    return (
        <React.Fragment>
            <div className="main">

                <Header isLoggedIn={loggedIn} setLoggedIn={setLoggedIn}/>
                <Switch>
                    <Route path="/configurations">
                        {
                            loggedIn
                                ? <Configurations/>
                                : <Redirect to={{pathname: "/"}}/>
                        }
                    </Route>
                    <Route path="/create_configuration">
                        {
                            loggedIn
                                ? <CreateConfiguration/>
                                : <Redirect to={{pathname: "/"}}/>
                        }
                    </Route>
                    <Route path="/categories">
                        {
                            loggedIn
                                ? <Categories/>
                                : <Redirect to={{pathname: "/"}}/>
                        }
                    </Route>
                    <Route path="/register">
                        {
                            <Register/>
                        }
                    </Route>
                    <Route path="/export">
                        {
                            loggedIn ? <Export/> : <Redirect to={{pathname: "/"}}/>
                        }
                    </Route>
                    <Route path="/results">
                        {
                            loggedIn ? <Results/> : <Redirect to={{pathname: "/"}}/>
                        }
                    </Route>
                    <Route path="/expenses">
                        {
                            loggedIn ? <Expenses/> : <Redirect to={{pathname: "/"}}/>
                        }
                    </Route>
                    <Route path="/">
                        <Login onLogin={() => {
                            setLoggedIn(true);
                            history.push("/configurations");
                        }}/>
                    </Route>
                </Switch>
            </div>

        </React.Fragment>
    );
}

export default App;
