import {useEffect, useState} from "react";
import {apiGet, apiPost} from "./api";
import {Button, Input, InputLabel, MenuItem, Select, TextField, Tooltip} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {Autocomplete} from "@material-ui/lab";

let languages = [{
    text: "Deutsch", code: "de"
},
    {
        text: "English", code: "en"
    }
    ,
    {
        text: "espanol", code: "es"
    }
    ,
    {
        text: "espanol (Latinoamérica)", code: "es_419"
    }
    ,
    {
        text: "français", code: "fr"
    }
    ,
    {
        text: "hrvatski", code: "hr"
    }
    ,
    {
        text: "italiano", code: "it"
    }
    ,
    {
        text: "Nederlands", code: "nl"
    }
    ,
    {
        text: "polski", code: "pl"
    }
    ,
    {
        text: "português (Brasil)", code: "pt_BR"
    }
    ,
    {
        text: "português (Portugal)", code: "pt_PT"
    }
    ,
    {
        text: "Tiếng Việt", code: "vi"
    }
    ,
    {
        text: "Türkçe", code: "tr"
    }
    ,
    {
        text: "русский", code: "ru"
    }
    ,
    {
        text: "العربية (Arabic)", code: "ar"
    }
    ,
    {
        text: "ไทย", code: "th"
    }
    ,
    {
        text: "한국어", code: "ko"
    }
    ,
    {
        text: "中文 (简体)", code: "zh_CN"
    }
    ,
    {
        text: "中文 (繁體)", code: "zh_TW"
    }
    ,
    {
        text: "日本語", code: "ja"
    }
    ,
    {
        text: "Acoli", code: "ach"
    }
    ,
    {
        text: "Afrikaans", code: "af"
    }
    ,
    {
        text: "Akan", code: "ak"
    }
    ,
    {
        text: "azərbaycan", code: "az"
    }
    ,
    {
        text: "Balinese", code: "ban"
    }
    ,
    {
        text: "Basa Sunda", code: "su"
    }
    ,
    {
        text: "Binisaya", code: "ceb"
    }
    ,
    {
        text: "Bork, bork, bork!", code: "xx_bork"
    }
    ,
    {
        text: "bosanski", code: "bs"
    }
    ,
    {
        text: "brezhoneg", code: "br"
    }
    ,
    {
        text: "català", code: "ca"
    }
    ,
    {
        text: "čeština", code: "cs"
    }
    ,
    {
        text: "chiShona", code: "sn"
    }
    ,
    {
        text: "Corsican", code: "co"
    }
    ,
    {
        text: "Cymraeg", code: "cy"
    }
    ,
    {
        text: "dansk", code: "da"
    }
    ,
    {
        text: "Èdè Yorùbá", code: "yo"
    }
    ,
    {
        text: "eesti", code: "et"
    }
    ,
    {
        text: "Elmer Fudd", code: "xx_elmer"
    }
    ,
    {
        text: "esperanto", code: "eo"
    }
    ,
    {
        text: "euskara", code: "eu"
    }
    ,
    {
        text: "Eʋegbe", code: "ee"
    }
    ,
    {
        text: "Filipino", code: "tl"
    }
    ,
    {
        text: "Filipino", code: "fil"
    }
    ,
    {
        text: "føroyskt", code: "fo"
    }
    ,
    {
        text: "Frysk", code: "fy"
    }
    ,
    {
        text: "Ga", code: "gaa"
    }
    ,
    {
        text: "Gaeilge", code: "ga"
    }
    ,
    {
        text: "Gàidhlig", code: "gd"
    }
    ,
    {
        text: "galego", code: "gl"
    }
    ,
    {
        text: "Guarani", code: "gn"
    }
    ,
    {
        text: "Hacker", code: "xx_hacker"
    }
    ,
    {
        text: "Haitian Creole", code: "ht"
    }
    ,
    {
        text: "Hausa", code: "ha"
    }
    ,
    {
        text: "ʻŌlelo Hawaiʻi", code: "haw"
    }
    ,
    {
        text: "Ichibemba", code: "bem"
    }
    ,
    {
        text: "Igbo", code: "ig"
    }
    ,
    {
        text: "Ikirundi", code: "rn"
    }
    ,
    {
        text: "Indonesia", code: "id"
    }
    ,
    {
        text: "interlingua", code: "ia"
    }
    ,
    {
        text: "isiXhosa", code: "xh"
    }
    ,
    {
        text: "isiZulu", code: "zu"
    }
    ,
    {
        text: "íslenska", code: "is"
    }
    ,
    {
        text: "Jawa", code: "jw"
    }
    ,
    {
        text: "Kinyarwanda", code: "rw"
    }
    ,
    {
        text: "Kiswahili", code: "sw"
    }
    ,
    {
        text: "Klingon", code: "tlh"
    }
    ,
    {
        text: "Kongo", code: "kg"
    }
    ,
    {
        text: "kreol morisien", code: "mfe"
    }
    ,
    {
        text: "Krio (Sierra Leone)", code: "kri"
    }
    ,
    {
        text: "Latin", code: "la"
    }
    ,
    {
        text: "latviešu", code: "lv"
    }
    ,
    {
        text: "lea fakatonga", code: "to"
    }
    ,
    {
        text: "lietuvių", code: "lt"
    }
    ,
    {
        text: "lingála", code: "ln"
    }
    ,
    {
        text: "Lozi", code: "loz"
    }
    ,
    {
        text: "Luba-Lulua", code: "lua"
    }
    ,
    {
        text: "Luganda", code: "lg"
    }
    ,
    {
        text: "magyar", code: "hu"
    }
    ,
    {
        text: "Malagasy", code: "mg"
    }
    ,
    {
        text: "Malti", code: "mt"
    }
    ,
    {
        text: "Melayu", code: "ms"
    }
    ,
    {
        text: "Nigerian Pidgin", code: "pcm"
    }
    ,
    {
        text: "norsk", code: "no"
    }
    ,
    {
        text: "norsk nynorsk", code: "nn"
    }
    ,
    {
        text: "Northern Sotho", code: "nso"
    }
    ,
    {
        text: "Nyanja", code: "ny"
    }
    ,
    {
        text: "o‘zbek", code: "uz"
    }
    ,
    {
        text: "Occitan", code: "oc"
    }
    ,
    {
        text: "Oromoo", code: "om"
    }
    ,
    {
        text: "Pirate", code: "xx_pirate"
    }
    ,
    {
        text: "română", code: "ro"
    }
    ,
    {
        text: "rumantsch", code: "rm"
    }
    ,
    {
        text: "Runasimi", code: "qu"
    }
    ,
    {
        text: "Runyankore", code: "nyn"
    }
    ,
    {
        text: "Seychellois Creole", code: "crs"
    }
    ,
    {
        text: "shqip", code: "sq"
    }
    ,
    {
        text: "slovenčina", code: "sk"
    }
    ,
    {
        text: "slovenščina", code: "sl"
    }
    ,
    {
        text: "Soomaali", code: "so"
    }
    ,
    {
        text: "Southern Sotho", code: "st"
    }
    ,
    {
        text: "srpski (Crna Gora)", code: "sr_ME"
    }
    ,
    {
        text: "srpski (latinica)", code: "sr_Latn"
    }
    ,
    {
        text: "suomi", code: "fi"
    }
    ,
    {
        text: "svenska", code: "sv"
    }
    ,
    {
        text: "te reo Māori", code: "mi"
    }
    ,
    {
        text: "Tswana", code: "tn"
    }
    ,
    {
        text: "Tumbuka", code: "tum"
    }
    ,
    {
        text: "türkmen dili", code: "tk"
    }
    ,
    {
        text: "Twi", code: "tw"
    }
    ,
    {
        text: "Wolof", code: "wo"
    }
    ,
    {
        text: "Řečtina",
        code: "el"
    }
];

let regions = [
    {text: "Albania", code: "AL"},
    {text: "Argentina", code: "AR"},
    {text: "Australia", code: "AU"},
    {text: "Austria", code: "AT"},
    {text: "Belgium", code: "BE"},
    {text: "Brazil", code: "BR"},
    {text: "Bulgaria", code: "BG"},
    {text: "Canada", code: "CA"},
    {text: "Chile", code: "CL"},
    {text: "Costa_Rica", code: "CR"},
    {text: "Croatia", code: "HR"},
    {text: "Cyprus", code: "CY"},
    {text: "Denmark", code: "DK"},
    {text: "Estonia", code: "EE"},
    {text: "Finland", code: "FI"},
    {text: "France", code: "FR"},
    {text: "Georgia", code: "GE"},
    {text: "Germany", code: "DE"},
    {text: "Greece", code: "GR"},
    {text: "Hong_Kong", code: "HK"},
    {text: "Hungary", code: "HU"},
    {text: "Iceland", code: "IS"},
    {text: "India", code: "IN"},
    {text: "Indonesia", code: "ID"},
    {text: "Ireland", code: "IE"},
    {text: "Israel", code: "IL"},
    {text: "Italy", code: "IT"},
    {text: "Japan", code: "JP"},
    {text: "Latvia", code: "LV"},
    {text: "Luxembourg", code: "LU"},
    {text: "Malaysia", code: "MY"},
    {text: "Mexico", code: "MX"},
    {text: "Moldova", code: "MD"},
    {text: "Netherlands", code: "NL"},
    {text: "New_Zealand", code: "NZ"},
    {text: "North_Macedonia", code: "MK"},
    {text: "Norway", code: "NO"},
    {text: "Poland", code: "PL"},
    {text: "Portugal", code: "PT"},
    {text: "Romania", code: "RO"},
    {text: "Serbia", code: "RS"},
    {text: "Singapore", code: "SG"},
    {text: "Slovakia", code: "SK"},
    {text: "Slovenia", code: "SI"},
    {text: "South_Africa", code: "ZA"},
    {text: "South_Korea", code: "KR"},
    {text: "Spain", code: "ES"},
    {text: "Sweden", code: "SE"},
    {text: "Switzerland", code: "CH"},
    {text: "Taiwan", code: "TW"},
    {text: "Thailand", code: "TH"},
    {text: "Turkey", code: "TR"},
    {text: "Ukraine", code: "UA"},
    {text: "United_Kingdom", code: "GB"},
    {text: "United_States", code: "US"},
    {text: "Vietnam", code: "VN"},
    {text: "Bosnia_Herzegovina", code: "BA"},
    {text: "Czechia", code: "CZ"},
    {text: "Philippines", code: "PH"},
    {text: "United Arab Emirates", code: "AE"},
    {text: "Nigeria", code: "NG"},
    {text: "Sri Lanka", code: "LK"},
]

const CreateConfiguration = () => {
    const [configuration, setConfiguration] = useState({
        keyword: "",
        language: null,
        region: null,
        frequency_type: "DAILY",
        frequency_spec: 1,
        rows_per_search: 30
    })
    const [error, setError] = useState("");
    const history = useHistory();

    return (
        <div>
            {error && <p style={{color: "red"}}>{error}</p>}
            <div>
                <Tooltip title="To create multiple keywords, please place each one on separate line">
                    <TextField multiline={true} rows={10} label="Keywords" value={configuration.keyword}
                               onChange={e => setConfiguration({...configuration, keyword: e.target.value})}/>
                </Tooltip>

                <br/>

                <Autocomplete
                    options={languages}
                    getOptionLabel={option => option ? option.text : ""}
                    style={{width: 200, marginTop: 10}}
                    value={configuration.language}
                    onChange={(e, newValue) => setConfiguration({...configuration, language: newValue})}
                    renderInput={(params) => <TextField {...params} label="Language" variant="outlined"/>}
                />


                <Autocomplete
                    options={regions}
                    getOptionLabel={region => region ? region.text : ""}
                    style={{width: 200, marginTop: 10}}
                    value={configuration.region}
                    onChange={(e, newVal) => setConfiguration({...configuration, region: newVal})}
                    renderInput={(params) => <TextField {...params} label="Regions" variant="outlined"/>}
                />

                <br/>

                <InputLabel id="Frequency">Frequency</InputLabel>
                <Select
                    labelId="Frequency"
                    value={configuration.frequency_type}
                    onChange={e => setConfiguration({...configuration, frequency_type: e.target.value})}
                >
                    <MenuItem value={"DAILY"}>Daily</MenuItem>
                    <MenuItem value={"WEEKLY"}>Weekly</MenuItem>
                    <MenuItem value={"MONTHLY"}>Monthly</MenuItem>
                </Select>

                {configuration.frequency_type == "WEEKLY" &&
                    <>
                        <InputLabel id="freq_weekly">Day of the week</InputLabel>
                        <Select
                            labelId="freq_weekly"
                            value={configuration.frequency_spec}
                            onChange={e => setConfiguration({...configuration, frequency_spec: e.target.value})}
                        >
                            <MenuItem value={"1"}>Monday</MenuItem>
                            <MenuItem value={"2"}>Tuesday</MenuItem>
                            <MenuItem value={"3"}>Wednesday</MenuItem>
                            <MenuItem value={"4"}>Thursday</MenuItem>
                            <MenuItem value={"5"}>Friday</MenuItem>
                            <MenuItem value={"6"}>Saturday</MenuItem>
                            <MenuItem value={"7"}>Sunday</MenuItem>
                        </Select>
                    </>
                }

                {configuration.frequency_type == "MONTHLY" &&
                    <>
                        <InputLabel id="freq_monthly">Day of month</InputLabel>
                        <Input labelId="freq_monthly" type="number" inputProps={{min: 1, max: 28}}
                               value={configuration.frequency_spec} onChange={e => setConfiguration({
                            ...configuration,
                            frequency_spec: parseInt(e.target.value)
                        })}/>
                    </>
                }

                <br/>

                <InputLabel id="rows_per_search">Results per search</InputLabel>
                <Input
                    labelId="rows_per_search"
                    type="number"
                    value={configuration.rows_per_search}
                    onChange={e => setConfiguration({...configuration, rows_per_search: parseInt(e.target.value)})}
                />

                <br/>

                <Button disabled={!configuration.keyword || !configuration.language || !configuration.region}
                        variant="contained" color="primary" onClick={() => {
                    apiPost("search_configurations", JSON.stringify({
                        keyword: configuration.keyword,
                        region: configuration.region.code,
                        language: configuration.language.code,
                        frequency: configuration.frequency_type + "-" + configuration.frequency_spec,
                        rows_per_search: configuration.rows_per_search
                    }))
                        .then(({status, text}) => {
                            if (status != 201) {
                                setError(text);
                            } else {
                                history.push("/configurations");
                            }
                        })
                }}>Create</Button>
            </div>
        </div>
    )
};

export default CreateConfiguration;