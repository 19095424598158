import {
    CircularProgress,
    IconButton, InputLabel, MenuItem, Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField, Tooltip
} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {useEffect, useState} from "react";
import {apiDelete, apiGet, apiPost} from "./api";
import {ArrowDownward, ArrowUpward, ExpandMore} from "@material-ui/icons";
import {adjust, filter, find, map, remove} from "ramda";
import HelpIcon from '@material-ui/icons/Help';

const MS_PER_DAY = 1000 * 60 * 60 * 24;

const addWeekRow = (rows, h) => {
    let date = new Date(h.date);
    let start = new Date(date - date.getDay() * MS_PER_DAY);
    let end = new Date(date + date.getDay() + MS_PER_DAY);
    rows.push({
        date: start.getFullYear() + "-" + (start.getMonth() + 1) + "-" + start.getDate() + " to " + end.getFullYear() + "-" + (end.getMonth() + 1) + "-" + end.getDate(),
        position: h.position,
        count: 1,
    });
}

const addMonthRow = (rows, h) => {
    let date = new Date(h.date);
    let start = new Date(date.getFullYear(), date.getMonth(), 1);
    let end;
    if (date.getMonth() == 11) {
        end = new Date(date.getFullYear() + 1, 0, 1);
    } else {
        end = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    }
    end = new Date(end - MS_PER_DAY);
    rows.push({
        date: start.getFullYear() + "-" + (start.getMonth() + 1) + "-" + start.getDate() + " to " + end.getFullYear() + "-" + (end.getMonth() + 1) + "-" + end.getDate(),
        position: h.position,
        count: 1,
    });
}

const HistoryTable = ({history, selection}) => {
    let rows = [];
    let lastDate = undefined;
    for (const h of history) {
        const date = new Date(h.date);
        switch (selection.granularity) {
            case 0: {
                rows.push({
                    date: h.date,
                    position: h.position,
                    count: 1,
                });
            }
                break;
            case 1: {
                if (lastDate) {
                    let diff = lastDate - date;
                    let days_diff = diff / MS_PER_DAY;
                    if (days_diff > 6 || date.getDay() + days_diff > 6) {
                        //Overflow -> it is another week
                        addWeekRow(rows, h);
                    } else {
                        let rowToChange = rows[rows.length - 1];
                        rowToChange.position = (rowToChange.position * rowToChange.count + h.position) / (rowToChange.count + 1);
                        rowToChange.count += 1;
                    }
                } else {
                    addWeekRow(rows, h);
                }

            }
                break;
            case 2: {
                if (lastDate) {
                    if (date.getFullYear() != lastDate.getFullYear() || date.getMonth() != lastDate.getMonth()) {
                        //Another month
                        addMonthRow(rows, h);
                    } else {
                        let rowToChange = rows[rows.length - 1];
                        rowToChange.position = (rowToChange.position * rowToChange.count + h.position) / (rowToChange.count + 1);
                        rowToChange.count += 1;
                    }
                } else {
                    addMonthRow(rows, h);
                }
            }
                break;
        }
        lastDate = date;
    }

    let hist = rows.map((hist, idx) => {
        let showArrow = false;
        let arrowUp = false;
        let diff = 0;
        if (idx < rows.length - 1) {
            showArrow = hist.position != rows[idx + 1].position;
            arrowUp = hist.position > rows[idx + 1].position;
            diff = rows[idx + 1].position - hist.position;
        }

        return (<TableRow>
            <TableCell>{hist.date}</TableCell>
            <TableCell>{Number.isInteger(hist.position) ? hist.position : hist.position.toFixed(2)}</TableCell>
            <TableCell>
                {showArrow && !arrowUp &&
                <>
                    <ArrowUpward style={{color: "green"}}/>
                    <span style={{color: "green"}}>+{Number.isInteger(diff) ? diff : diff.toFixed(2)}</span>
                </>
                }
                {showArrow && arrowUp &&
                <>
                    <ArrowDownward style={{color: "red"}}/>
                    <span style={{color: "red"}}>{Number.isInteger(diff) ? diff : diff.toFixed(2)}</span>
                </>
                }
            </TableCell>
        </TableRow>)
    });
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>{selection.granularity == 0 ? "Position" : "Average position"}</TableCell>
                    <TableCell>{selection.granularity == 0 ? "Direction" : "Average direction"}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {hist}
            </TableBody>
        </Table>
    )
}

const get_results = (selection, setResults) => {
    setResults({results: null, searching: true});

    let query_param;
    if (selection.category && !selection.configuration) {
        query_param = "?category=" + selection.category;
    } else if (!selection.category && selection.configuration) {
        query_param = "?search_configuration_id=" + selection.configuration.id;
    } else if (selection.category && selection.configuration) {
        query_param = "?search_configuration_id=" + selection.configuration.id + "&category=" + selection.category;
    }

    if (query_param) {
        apiGet("results" + query_param)
            .then(({text}) => {
                let res = JSON.parse(text);
                setResults({
                    results: res,
                    searching: false
                });
            })
    }
}

const configurationRow = (configurations, row) => {
    let config = find(c => c.id == row.search_configuration_id, configurations);
    return <TableCell>{config.keyword + " - " + config.language + " - " + config.region}</TableCell>;
}

const getRowColor = (categories, category, is_ad) => {
    if (is_ad) {
        return "gray";
    } else if (categories[category] && categories[category].color) {
        return categories[category].color;
    } else {
        return "white";
    }
}

const resultRows = (results, selection, categories, configurations, setResults) => {
    let no_ad_idx = 1;
    return results.results.map((res, idx) =>
        <>
            <TableRow key={res.id} style={{
                backgroundColor: getRowColor(categories, res.category_id, res.is_ad),
                borderBottom: "2px solid black"
            }}>
                {selection.category && !selection.configuration && configurationRow(configurations, res)}
                <TableCell><b>{res.is_ad ? "---" : (res.position ? no_ad_idx++ : res.position)}</b></TableCell>
                <TableCell style={{wordWrap: "break-word", maxWidth: 200}}><b><a
                    href={"https://" + res.url} target="_blank">{res.url}</a></b></TableCell>
                {!selection.category && selection.configuration &&
                <>
                    {res.is_ad ? <TableCell>AD</TableCell> :
                        <Autocomplete
                            options={Object.keys(categories)}
                            getOptionLabel={cat => {
                                if (cat) {
                                    return categories[cat].category;
                                } else {
                                    return "";
                                }
                            }}
                            style={{width: 400, marginTop: 10, marginRight: 10}}
                            value={res.category_id}
                            onChange={(e, newValue) => {
                                if (res.category_id) {
                                    apiDelete("urlsForCategory?id=" + res.url_for_category_id)
                                        .then(({status}) => {
                                            if (status == 200) {
                                                const deleted_id = res.url_for_category_id;
                                                setResults({
                                                    ...results, results: map(r => {
                                                        if (r.url_for_category_id == deleted_id) {
                                                            r.category_id = null;
                                                            r.url_for_category_id = null;
                                                        }
                                                        return r;
                                                    }, results.results)
                                                });
                                            }
                                        })
                                }
                                if (newValue) {
                                    apiPost("urlsForCategory", JSON.stringify({
                                        url: res.url,
                                        category_id: parseInt(newValue)
                                    })).then(({status, text}) => {
                                        if (status == 201) {
                                            setResults({
                                                ...results, results: adjust(idx, r => {
                                                    const parsedResp = JSON.parse(text);
                                                    res.category_id = parsedResp.category_id;
                                                    res.url_for_category_id = parsedResp.id;
                                                    return res
                                                }, results.results)
                                            });
                                        }
                                    })
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="Category"
                                                                variant="outlined"/>}
                        />}
                </>
                }
                <TableCell>
                    <IconButton onClick={() => {
                        if (res.showHistory) {
                            setResults({
                                ...results,
                                results: adjust(idx, row => ({
                                    ...row,
                                    showHistory: false,
                                    searching: false
                                }), results.results)
                            })
                        } else {
                            setResults({
                                ...results,
                                results: adjust(idx, row => ({
                                    ...row,
                                    showHistory: true,
                                    searching: true
                                }), results.results)
                            })
                            let url = "position_history?search_configuration_id=" + res.search_configuration_id + "&url=" + encodeURIComponent(res.url);
                            apiGet(url)
                                .then(({text}) => {
                                    let history = JSON.parse(text);
                                    setResults({
                                        ...results,
                                        results: adjust(idx, row => ({
                                            ...row,
                                            showHistory: true,
                                            searching: false,
                                            history
                                        }), results.results)
                                    });
                                });
                        }
                    }}>
                        <ExpandMore/>
                    </IconButton>
                </TableCell>
            </TableRow>
            {res.showHistory &&
            <TableRow>
                <TableCell colSpan={3}>
                    {res.searching &&
                    <CircularProgress/>
                    }
                    {!res.searching && res.history &&
                    <HistoryTable history={res.history} selection={selection}/>
                    }
                </TableCell>

            </TableRow>
            }
        </>
    )
}

const Results = () => {
    const [configurations, setConfigurations] = useState(null);
    const [categories, setCategories] = useState(null);
    const [selection, setSelection] = useState({
        granularity: 0,
        category: null,
        configuration: null
    })
    const [results, setResults] = useState({
        searching: false,
        results: null
    });

    useEffect(() => {
        Promise.all([apiGet("search_configurations"), apiGet("categories")])
            .then(values => {
                let json = JSON.parse(values[0].text);
                for (let conf of json) {
                    conf.showHistory = false;
                }
                setConfigurations(json)

                json = JSON.parse(values[1].text);
                let used_categories = {};
                json = filter(cat => {
                    if (!used_categories[cat.category]) {
                        used_categories[cat.category] = true;
                        cat.showHistory = false;
                        return true;
                    }
                    return false;
                }, json);
                json = json.reduce(function (map, obj) {
                    map[obj.id] = obj;
                    return map;
                }, {});
                setCategories(json);
            })
    }, []);

    return (
        <div>
            {configurations && categories &&
            <div>
                <Autocomplete
                    options={Object.keys(categories)}
                    getOptionLabel={cat => {
                        if (cat) {
                            return categories[cat].category;
                        } else {
                            return "";
                        }
                    }}
                    style={{width: 400, marginTop: 10, marginRight: 10, float: "left"}}
                    value={selection.category}
                    onChange={(e, newValue) => {
                        const newSelection = {...selection, category: newValue};
                        setSelection(newSelection);
                        get_results(newSelection, setResults);
                    }}
                    renderInput={(params) => <TextField {...params} label="Category" variant="outlined"/>}
                />

                <Autocomplete
                    options={configurations}
                    getOptionLabel={conf => {
                        if (conf) {
                            return conf.keyword + " - " + conf.language + " - " + conf.region;
                        } else {
                            return "";
                        }
                    }}
                    style={{width: 400, marginTop: 10, marginRight: 10, float: "left"}}
                    value={selection.configuration}
                    onChange={(e, newValue) => {
                        const newSelection = {...selection, configuration: newValue};
                        setSelection(newSelection);
                        get_results(newSelection, setResults);
                    }}
                    renderInput={(params) => <TextField {...params} label="Configuration" variant="outlined"/>}
                />

                <InputLabel id="granularity">Granularity<Tooltip
                    title="Please note that average position does not take into account days, when url was not present in results at all"><HelpIcon/></Tooltip></InputLabel>
                <Select
                    labelId="granularity"
                    value={selection.granularity}
                    onChange={e => setSelection({...selection, granularity: e.target.value})}
                >
                    <MenuItem value={0}>Days</MenuItem>
                    <MenuItem value={1}>Weeks</MenuItem>
                    <MenuItem value={2}>Months</MenuItem>
                </Select>
            </div>
            }
            {results.searching && <CircularProgress style={{marginLeft: 178, marginTop: 10}}/>}
            {results.results &&
            <>
                <Table>
                    <TableHead>
                        <TableRow>
                            {selection.category && !selection.configuration && <TableCell>Configuration</TableCell>}
                            <TableCell>Position</TableCell>
                            <TableCell>Url</TableCell>
                            {!selection.category && selection.configuration && <TableCell>Category</TableCell>}
                            <TableCell>History</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {resultRows(results, selection, categories, configurations, setResults)}
                    </TableBody>
                </Table>
            </>
            }
        </div>
    )
};

export default Results