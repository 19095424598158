import {Button, TextField} from "@material-ui/core";
import {apiPost, getApiUrl} from "./api";
import {useState} from "react";

const Export = () => {
    const [start, setStart] = useState(new Date().toLocaleString("en-CA").split(",")[0]);
    const [end, setEnd] = useState(new Date().toLocaleString("en-CA").split(",")[0]);

    return (
        <div>
            <TextField
                type="date"
                label="From"
                value={start}
                onChange={e => {
                    setStart(e.target.value)
                }}
            />
            <br/>
            <TextField
                type="date"
                label="To"
                value={end}
                onChange={e => {
                    setEnd(e.target.value)
                }}
            />
            <br/>


            <Button variant="contained" color="primary" onClick={() => {
                let downloadLink = document.createElement("a");
                downloadLink.href = getApiUrl() + "export" + "?start=" + start + "&end=" + end;
                downloadLink.download = "";
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }}>Export!</Button>
        </div>
    )
}

export default Export