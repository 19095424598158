import {useEffect, useState} from "react";
import {apiDelete, apiGet, apiPost} from "./api";
import {
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Input,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField, Typography
} from "@material-ui/core";
import React from "react";
import {remove} from "ramda";

const TestDialogContent = ({id}) => {
    let [urls, setUrls] = useState([]);
    let [url, setUrl] = useState("");
    useEffect(() => {
        apiGet("urlsForCategory?category_id=" + id)
            .then(({text}) => {
                setUrls(JSON.parse(text));
            })
    }, [])
    return (
        <div>
            <TextField
                value={url}
                onChange={e => setUrl(e.target.value)}
                label="Url"
            />
            <Button onClick={() => {
                apiPost("urlsForCategory", JSON.stringify({
                    url,
                    category_id: id
                })).then(({status, text}) => {
                    if (status == 201) {
                        setUrls([...urls, JSON.parse(text)])
                    }
                })
            }}>Create</Button>
            <br/>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Url</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {urls.map((url, idx) =>
                        <TableRow key={url.id}>
                            <TableCell>{url.url}</TableCell>
                            <TableCell><Button onClick={() => {
                                apiDelete("urlsForCategory?id=" + url.id)
                                    .then(({status}) => {
                                        if (status == 200) {
                                            setUrls(remove(idx, 1, urls));
                                        }
                                    })
                            }}>Delete</Button></TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    )
}

const Categories = () => {
    let [categories, setCategories] = useState([]);
    useEffect(() => {
        apiGet("categories")
            .then(({text}) => {
                setCategories(JSON.parse(text));
            })
    }, [])
    let [category, setCategory] = useState("");
    let [modalId, setModalId] = useState(null);

    return (
        <div>
            <TextField
                value={category}
                onChange={e => setCategory(e.target.value)}
                style={{marginLeft: "30px"}}
                label="Category"
            />

            <Button style={{marginTop: "10px", marginLeft: "30px"}} variant="contained" color="primary" onClick={() => {
                apiPost("categories", JSON.stringify({category}))
                    .then(({status, text}) => {
                        if (status == 201) {
                            setCategories([JSON.parse(text), ...categories]);
                            setCategory("");
                        }
                    })

            }}>Create</Button>


            {categories.length > 0 &&
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Category</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {categories.map(cat =>
                        <TableRow>
                            <TableCell>{cat.category}</TableCell>
                            <TableCell>
                                <Button variant="contained" color="primary" onClick={() => setModalId(cat.id)}>Edit</Button>
                            </TableCell>
                            <TableCell>
                                <Button variant="contained" color="primary" onClick={() => {
                                    apiDelete("categories", JSON.stringify({id: cat.id}))
                                        .then(({status}) => {
                                            if (status == 200) {
                                                setCategories(categories.filter(c => c != cat));
                                            }
                                        })
                                }}>Delete</Button>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            }

            <Dialog onClose={() => setModalId(null)} aria-labelledby="customized-dialog-title" open={!!modalId}>
                <DialogTitle id="customized-dialog-title" onClose={() => setModalId(null)}>
                    Urls
                </DialogTitle>
                <DialogContent>
                    <TestDialogContent id={modalId}/>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setModalId(null)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Categories