import {useEffect, useState} from "react";
import {apiGet} from "./api";
import {IconButton, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {ArrowDownward, ArrowRight, ArrowUpward, ExpandMore} from "@material-ui/icons";
import {adjust} from "ramda";

const formatter = new Intl.NumberFormat("CZ-cs", {
    style: 'currency',
    currency: 'CZK',
    maximumSignificantDigits: 2,
    minimumSignificantDigits: 2,

})

const ExpenseRow = ({expense}) => {
    const [details, setDetails] = useState([]);
    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton onClick={() => {
                        if (details.length == 0) {
                            apiGet("expenses_for_day?date=" + expense[0])
                                .then(({text}) => setDetails(JSON.parse(text)))
                        } else {
                            setDetails([]);
                        }
                    }}>
                        <ExpandMore/>
                    </IconButton>

                    {expense[0]}</TableCell>
                <TableCell>{(expense[1] / 100).toFixed(2)} Kč</TableCell>
            </TableRow>
            {details.length > 0 &&
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Search query</TableCell>
                            <TableCell>Language</TableCell>
                            <TableCell>Region</TableCell>
                            <TableCell>Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {details.map(detail =>
                            <TableRow>
                                <TableCell>{detail.search_query}</TableCell>
                                <TableCell>{detail.language}</TableCell>
                                <TableCell>{detail.region}</TableCell>
                                <TableCell>{(detail.price / 100).toFixed(2)} Kč</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            }
        </>
    )
}
const Expenses = ({}) => {
    const [expenses, setExpenses] = useState([]);

    useEffect(() => {
        apiGet("expenses")
            .then(({text}) => setExpenses(JSON.parse(text)))
    }, []);
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>At</TableCell>
                    <TableCell>Price</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {expenses.map(expense => <ExpenseRow expense={expense}/>
                )}
            </TableBody>
        </Table>
    )
}

export default Expenses