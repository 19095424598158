import {useState} from "react";
import {Button, TextField, Typography} from "@material-ui/core";
import {apiPost} from "./api";

const Register = () => {
    let [username, setUsername] = useState("");
    let [password, setPassword] = useState("");
    let [infoMessage, setInfoMessage] = useState({
        text: "",
        show: false,
        positive: false
    });

    return (
        <div>
            <div>
                <TextField
                    type="text" label="Username" value={username}
                    onChange={e => setUsername(e.target.value)}/>
                <TextField onKeyPress={e => {
                    if (e.keyCode == 13) {

                    }
                }} type="password" label="Password" value={password}
                           onChange={e => setPassword(e.target.value)}/>
                <Button variant="contained" color="primary" onClick={() => {
                    apiPost("users/register", JSON.stringify({username, password}))
                        .then(({status}) => {
                            if (status == 200) {
                                setInfoMessage({
                                    text: "Registered sucessfully. Please login",
                                    show: true,
                                    positive: true
                                })
                            } else {
                                setInfoMessage({
                                    text: "Failed to register. Please try again",
                                    show: true,
                                    positive: true
                                })
                            }
                        })
                }}>Register</Button>
            </div>
            {infoMessage.show &&
                <Typography variant="body1" style={{color: infoMessage.positive ? "green" : "red"}}>
                    {infoMessage.text}
                </Typography>
            }


        </div>
    )
}

export default Register