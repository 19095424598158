import { useEffect, useState } from "react";
import { apiDelete, apiGet, apiPost, apiPut } from "./api";
import {
    Button,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    MenuItem,
    Select,
    InputLabel,
    Input
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { BubbleChart } from "@material-ui/icons";

const Frequency = ({ frequency }) => {
    const split = frequency.split("-");
    if (frequency == "DAILY") {
        return frequency;
    } else if (frequency.startsWith("WEEKLY")) {
        let day = "Monday";
        if (split[1] == 2) {
            day = "Tuesday"
        } else if (split[1] == 3) {
            day = "Wednesday"
        } else if (split[1] == 4) {
            day = "Thursday"
        } else if (split[1] == 5) {
            day = "Friday"
        } else if (split[1] == 6) {
            day = "Saturday"
        } else if (split[1] == 7) {
            day = "Sunday"
        }
        return split[0] + " - " + day;
    } else {
        return split[0] + " - " + split[1] + ".";
    }
}

const Configurations = () => {
    let [configs, setConfigs] = useState([])
    let [confirmation, setConfirmation] = useState({
        opened: false,
        id: undefined
    });
    let history = useHistory();

    let [editConfig, setEditConfig] = useState({
        opened: false,
        config: null
    })


    useEffect(() => {
        apiGet("search_configurations")
            .then(({ text }) => {
                let resp = JSON.parse(text);
                setConfigs(resp);
            })
    }, []);

    return (
        <div>
            <Button variant="contained" color="primary" onClick={() => {
                history.push("/create_configuration");
            }}>Create</Button>
            {configs &&
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Search query</TableCell>
                            <TableCell>Language</TableCell>
                            <TableCell>Region</TableCell>
                            <TableCell>Frequency</TableCell>
                            <TableCell>Rows per search</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {configs.map(config =>
                            <TableRow>
                                <TableCell>{config.keyword}</TableCell>
                                <TableCell dangerouslySetInnerHTML={{ __html: config.language }}></TableCell>
                                <TableCell>{config.region}</TableCell>
                                <TableCell>
                                    <Frequency frequency={config.frequency} />
                                </TableCell>
                                <TableCell>{config.rows_per_search}</TableCell>
                                <TableCell>
                                    <Button variant="contained" color="primary" onClick={() => {
                                        setEditConfig({ config: null, opened: true })
                                        apiGet("search_configuration/" + config.id)
                                            .then(({ text }) => {
                                                setEditConfig({config: JSON.parse(text), opened: true})
                                            })
                                    }}>Edit</Button>
                                    <Button variant="contained" color="primary" onClick={() => {
                                        setConfirmation({
                                            opened: true,
                                            id: config.id
                                        });
                                    }}>DELETE</Button>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            }
            <Dialog onClose={() => setEditConfig({config: null, opened: false})} aria-labelledby="customized-dialog-title" open={editConfig.opened}>
                <DialogTitle>
                    Edit search configuration
                </DialogTitle>
                <DialogContent>
                    {editConfig.config && <SelectFrequency frequency={editConfig.config.frequency} setFrequency={f => setEditConfig({...editConfig, config: {...editConfig.config, frequency: f}})}/>}

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        apiPut("search_configurations", JSON.stringify({ id: editConfig.config.id, frequency: editConfig.config.frequency }))
                            .then(({text}) => { 
                                const newConfig = JSON.parse(text);
                                setConfigs(configs.map(cfg => cfg.id == newConfig.id ? newConfig : cfg));
                            })
                        setEditConfig({config: null, opened: false});
                    }}>Save</Button>
                    <Button onClick={() => setEditConfig({config: null, opened: false})} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={confirmation.opened} onClose={() => setConfirmation({ opened: false })}>
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        When you delete this search configuration, all corresponding search results will be also deleted. Are you sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmation({ opened: false })}>No</Button>
                    <Button onClick={() => {
                        setConfirmation({ opened: false });
                        apiDelete("search_configurations?id=" + confirmation.id + "")
                            .then(({ status }) => {
                                if (status == 200) {
                                    setConfigs(configs.filter(c => c.id != confirmation.id));
                                }
                            })
                    }}>Yes</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const SelectFrequency = ({ frequency, setFrequency }) => {
    const type = frequency.split("-")[0];
    const spec = frequency.includes("-") ? frequency.split("-")[1] : 1;

    const saveSelection = (type, spec) => {
        if (type == "DAILY") {
            setFrequency(type);
        } else {
            setFrequency(type + "-" + spec);
        }
    }

    return (
        <div>
            <InputLabel id="Frequency">Frequency</InputLabel>
            <Select
                labelId="Frequency"
                value={type}
                onChange={e => saveSelection(e.target.value, spec)}
            >
                <MenuItem value={"DAILY"}>Daily</MenuItem>
                <MenuItem value={"WEEKLY"}>Weekly</MenuItem>
                <MenuItem value={"MONTHLY"}>Monthly</MenuItem>
            </Select>

            {type == "WEEKLY" &&
                <>
                    <InputLabel id="freq_weekly">Day of the week</InputLabel>
                    <Select
                        labelId="freq_weekly"
                        value={spec}
                        onChange={e => saveSelection(type, e.target.value)}
                    >
                        <MenuItem value={"1"}>Monday</MenuItem>
                        <MenuItem value={"2"}>Tuesday</MenuItem>
                        <MenuItem value={"3"}>Wednesday</MenuItem>
                        <MenuItem value={"4"}>Thursday</MenuItem>
                        <MenuItem value={"5"}>Friday</MenuItem>
                        <MenuItem value={"6"}>Saturday</MenuItem>
                        <MenuItem value={"7"}>Sunday</MenuItem>
                    </Select>
                </>
            }

            {type == "MONTHLY" &&
                <>
                    <InputLabel id="freq_monthly">Day of month</InputLabel>
                    <Input labelId="freq_monthly" type="number" inputProps={{ min: 1, max: 28 }} value={spec} onChange={e => saveSelection(type, parseInt(e.target.value))} />
                </>
            }
        </div>
    )
}

export default Configurations